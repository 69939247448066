<template>
    <component v-bind="$attrs" :is="tag" :ref="refName" v-on="$listeners" v-html="renderedHtml"></component>
</template>
<script>
import { getVideoResourceHandler, getAudioResourceHandler } from '@/shared';
import { mapState } from 'vuex';
import { browser } from '@jz/utils';

export default {
    name: 'HtmlRender',
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        html: {
            type: String,
            default() {
                return '';
            },
        },
    },
    data() {
        return {
            refName: 'htmlRenderer',
        };
    },
    computed: {
        ...mapState('app', ['isJzCdnFlowLimit']),
        containsIframe() {
            return this.html.includes('iframe');
        },
        renderedHtml() {
            if (this.containsIframe) {
                if (this.$isServer) {
                    return this.getHtmlWithMediaHandler();
                } else {
                    return '';
                }
            } else {
                return this.getHtmlWithMediaHandler();
            }
        },
    },
    watch: {
        html() {
            this.activateContentIfContainsIframe();
        },
    },
    mounted() {
        this.activateContentIfContainsIframe();
        this.handleIOSWechatVideoPoster();
    },
    methods: {
        getHtmlWithMediaHandler() {
            if (this.isJzCdnFlowLimit) {
                const _videoResourceHandler = getVideoResourceHandler();
                const _audioResourceHandler = getAudioResourceHandler();

                return _audioResourceHandler.srcLazyloadify(_videoResourceHandler.srcLazyloadify(this.html));
            }
            return this.html;
        },
        activateContentIfContainsIframe() {
            // if (this.containsIframe) {
                jm(this.$refs[this.refName]).html(this.html);
            // }
        },
        handleIOSWechatVideoPoster() {
            // 处理 ios & wechat poster 盖住视频播放问题
            // 解决方式： ios & wechat 下 video 点击播放后将 poster 置为空

            // 处理 ios & wechat 点击播放会缩小视频高度，导致同一点击事件触发了下方元素的点击事件，比如图片的幻灯片
            // 解决方式： ios & wechat 下 video 点击播放后将 min-height 设置为初始高度

            /**
             * [反馈工单] # 616391
             *   https://it.faisco.biz/page/workOrder/workOrderDetail?recordId=616391
             */
            if (this.html.includes('video') && browser.isIos() && browser.isWeChat()) {
                const heights = new Map(); // <src, initialHeight>, default 350

                const $els = jm(this.$refs[this.refName]).find('video[src]');
                const videos = Array.from($els);
                const realVideos = videos.filter((v) => v.getAttribute('src') !== '');

                realVideos.forEach((rv) => {
                    heights.set(rv.getAttribute('src'), jm(rv).height());
                });

                $els.on('play.ios', function () {
                    jm(this)
                        .attr('poster', '')
                        .css('min-height', (heights.get(this.getAttribute('src')) || 350) + 'px');
                });
            }
        },
    },
};
</script>
